import { FC, memo, ReactElement, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import VideoStream from './VideoStream';
import { useAppSelector } from '../../../../store';
import { setStylesForVideoStreamContainer } from '../../../common/helpers/vehicleStyles';
import classes from '../../../styles/stream.module.css';
import {
  setCameras,
  createActiveParticipants,
} from '../../../../store/vehicle/vehicle.slice';

const VideoStreamContainer: FC = memo((): ReactElement => {
  const camerasData = useAppSelector(
    (state) => state.socket.vehicleMetrics?.cameras_data
  );
  const collapseAdditionalInfoSection = useAppSelector(
    (state) => state.app.collapseAdditionalInfoSection
  );

  const dispatch = useDispatch();

  const pinnedMap = useAppSelector((state) => state.vehicles.pinnedMap);
  const camerasState = useAppSelector((state) => state.vehicles.camerasState);

  const [pinnedCamera, setPinnedCamera] = useState<number | null>(null);
  const [videoFullScreenCamera, setVideoFullScreenCamera] = useState<
    number | null
  >(null);

  const totalCameras: number[] | any = useMemo(
    (): number[] | any => camerasData || [],
    [camerasData]
  );

  useEffect(() => {
    if (totalCameras.length !== camerasState.length) {
      const cameraStates = totalCameras.map(() => false);
      dispatch(setCameras(cameraStates));
      dispatch(createActiveParticipants({ totalCameras }));
    }
  }, [totalCameras, dispatch]);

  return (
    <Box
      className={classes.videoStreamContainer}
      style={setStylesForVideoStreamContainer(
        totalCameras,
        collapseAdditionalInfoSection,
        pinnedCamera,
        pinnedMap,
        videoFullScreenCamera
      )}
    >
      {totalCameras.map(
        (
          camera: {
            frame_size: { width: number; height: number };
            real_name: string;
          },
          index: number
        ): ReactElement => (
          <VideoStream
            key={camera.real_name}
            camera={index}
            pinnedCamera={pinnedCamera}
            setPinnedCamera={setPinnedCamera}
            videoFullScreenCamera={videoFullScreenCamera}
            setVideoFullScreenCamera={setVideoFullScreenCamera}
            totalCameras={totalCameras.length}
            totalThreeElements={totalCameras.length === 3 && index === 2}
          />
        )
      )}
    </Box>
  );
});

export default VideoStreamContainer;
