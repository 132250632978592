import { FC, memo, ReactElement, useState } from 'react';
import { Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import CellTowerIcon from '@mui/icons-material/CellTower';
import SettingsIcon from '@mui/icons-material/Settings';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { socketSend } from '../../../../infra/common/socketService';
import {
  startEngineCommand,
  manualControlCommand,
  stopEngineCommand,
  pumpCommands,
} from '../../../../infra/common/socketService/commands/commonCommands';
import { useAppDispatch, useAppSelector } from '../../../../store';
import {
  setController,
  setEngineRunning,
  setManualControlRunning,
  setShowSelectController,
} from '../../../../store/vehicle/vehicle.slice';
import {
  VEHICLE_TYPES,
  vehicleActionsByType,
} from '../../../../domain/vehicle';
import StartManualIcon from '../../../assets/StartManualIcon';
import StopManualIcon from '../../../assets/StopManualIcon';
import classes from '../../../styles/vehicle.module.css';
import StartEngine from '../../../assets/StartEngine';
import CarElementsControls from './CarElementsControls';
import DroneElementsControls from './DroneElementsControls';
import useRadiomaster from '../../../common/hooks/useRadiomaster';

let armTimeout: NodeJS.Timeout;

const CommonControls: FC = memo((): ReactElement => {
  const dispatch = useAppDispatch();
  const [isMousePressed, setIsMousePressed] = useState(false);
  const vehicleType = useAppSelector(
    (state) => state.vehicles.activeVehicle?.vehicle_type
  );
  const controller = useAppSelector((state) => state.vehicles.controller);

  const [selectedController, setSelectedController] = useState(controller);
  const [controllerTypeVisible, setControllerTypeVisible] =
    useState<boolean>(false);
  const timeProcessing = useAppSelector((state) => state.app.timeProcessing);
  const engineRunning = useAppSelector((state) => state.vehicles.engineRunning);
  const [pumpMainRunning, setPumpMainRunning] = useState<boolean>(false);
  const [pumpEngineRunning, setPumpEngineRunning] = useState<boolean>(false);
  const manualControlRunning = useAppSelector(
    (state) => state.vehicles.manualControlRunning
  );
  const showSelectController = useAppSelector(
    (state) => state.vehicles.showSelectController
  );
  const { stopReadingRadiomaster, connectAndReadSerial } = useRadiomaster();

  const manualControlMetric = useAppSelector(
    (state) => state.socket.vehicleMetrics?.is_manual
  );

  const { t } = useTranslation();

  const startEngine = async (): Promise<void> => {
    if (!engineRunning) {
      await socketSend(
        startEngineCommand(
          vehicleActionsByType[vehicleType as VEHICLE_TYPES].armCommand
        ),
        timeProcessing
      );
    } else if (
      vehicleActionsByType[vehicleType as VEHICLE_TYPES].stopArmCommandAvailable
    ) {
      await socketSend(
        stopEngineCommand(
          vehicleActionsByType[vehicleType as VEHICLE_TYPES]?.stopArmCommand
        ),
        timeProcessing
      );
    }
    await dispatch(setEngineRunning(!engineRunning));
  };

  const pumpMainCommands = async (): Promise<void> => {
    if (!pumpMainRunning) {
      await socketSend(pumpCommands('boat_pump_main_on'), timeProcessing);
      setPumpMainRunning(true);
    } else {
      await socketSend(pumpCommands('boat_pump_main_off'), timeProcessing);
      setPumpMainRunning(false);
    }
  };

  const pumpEngineCommands = async (): Promise<void> => {
    if (!pumpEngineRunning) {
      await socketSend(pumpCommands('boat_pump_engine_on'), timeProcessing);
      setPumpEngineRunning(true);
    } else {
      await socketSend(pumpCommands('boat_pump_engine_off'), timeProcessing);
      setPumpEngineRunning(false);
    }
  };

  const startEngineKHTI = async (): Promise<void> => {
    if (manualControlMetric) {
      if (!engineRunning) {
        setIsMousePressed(true);
        await socketSend(
          startEngineCommand(
            vehicleActionsByType[vehicleType as VEHICLE_TYPES].armCommand
          ),
          timeProcessing
        );
        await dispatch(setEngineRunning(true));
        armTimeout = setTimeout(async () => {
          setIsMousePressed(false);
          await socketSend(
            stopEngineCommand(
              vehicleActionsByType[vehicleType as VEHICLE_TYPES]?.stopArmCommand
            ),
            timeProcessing
          );
          await dispatch(setEngineRunning(false));
        }, 7000);
      }
    }
  };

  const stopEngineKHTI = async () => {
    if (manualControlMetric) {
      if (engineRunning) {
        clearTimeout(armTimeout);
        setIsMousePressed(false);
        await socketSend(
          stopEngineCommand(
            vehicleActionsByType[vehicleType as VEHICLE_TYPES]?.stopArmCommand
          ),
          timeProcessing
        );
        await dispatch(setEngineRunning(false));
      }
    }
  };

  const startManualControl = async (): Promise<void> => {
    if (!manualControlMetric) {
      await socketSend(manualControlCommand('start_manual'), timeProcessing);
      // if (controller === 'radiomaster') {
      //   connectAndReadSerial();
      // }
    }
    if (manualControlMetric) {
      await socketSend(manualControlCommand('stop_manual'), timeProcessing);
      if (controller === 'radiomaster') {
        stopReadingRadiomaster();
      }
    }
    // await dispatch(setManualControlRunning(!manualControlRunning));
  };

  const displayVehicleSpecialFunctionality = (
    vehicleType: VEHICLE_TYPES
  ): ReactElement | null => {
    if (
      vehicleActionsByType[vehicleType as VEHICLE_TYPES]
        ?.specialFunctionalityAvailable
    ) {
      if (vehicleType === VEHICLE_TYPES.CAR) {
        return <CarElementsControls />;
      }

      if (
        vehicleType === VEHICLE_TYPES.DRONE ||
        vehicleType === VEHICLE_TYPES.PLANE
      ) {
        return <DroneElementsControls />;
      }

      return null;
    }

    return null;
  };

  return (
    <>
      <Box className={classes.controlItemContainer}>
        {vehicleType === VEHICLE_TYPES.TRACTOR_KHTI ? (
          <IconButton
            size='large'
            disabled={!manualControlMetric}
            style={!manualControlMetric ? { opacity: '0.1' } : { opacity: '1' }}
            className={
              !engineRunning
                ? classes.startEngineStart
                : classes.startEngineStop
            }
            onClick={isMousePressed ? stopEngineKHTI : startEngineKHTI}
          >
            <StartEngine />
          </IconButton>
        ) : (
          <IconButton
            size='large'
            className={
              !engineRunning
                ? classes.startEngineStart
                : classes.startEngineStop
            }
            onClick={startEngine}
          >
            <StartEngine />
          </IconButton>
        )}

        <Typography variant='caption' noWrap className={classes.commandText}>
          {!engineRunning
            ? t('vehicle.startEngineLabel')
            : t('vehicle.stopEngineLabel')}
        </Typography>
      </Box>
      <Box className={classes.controlItemContainer}>
        <IconButton
          size='large'
          className={
            manualControlMetric
              ? classes.manualControlStart
              : classes.manualControlStop
          }
          onClick={startManualControl}
        >
          {manualControlMetric ? <StopManualIcon /> : <StartManualIcon />}
        </IconButton>
        <Typography variant='caption' noWrap className={classes.commandText}>
          {!manualControlMetric
            ? t('vehicle.startManualLabel')
            : t('vehicle.stopManualLabel')}
        </Typography>
      </Box>
      {vehicleType === VEHICLE_TYPES.DRONE ||
      vehicleType === VEHICLE_TYPES.PLANE ||
      vehicleType === VEHICLE_TYPES.BOAT ? (
        <Box
          className={classes.controlItemContainer}
          onClick={() => setControllerTypeVisible(!controllerTypeVisible)}
        >
          {controllerTypeVisible ? (
            <Box className={classes.sweepersType}>
              <Typography
                variant='caption'
                sx={{ display: 'flex', alignItems: 'center' }}
                className={
                  selectedController !== 'gamepad'
                    ? classes.sweepersTypeItem
                    : classes.sweepersNonTypeItem
                }
                onClick={() => {
                  setSelectedController('gamepad');
                  dispatch(setController('gamepad'));
                  setControllerTypeVisible(false);
                }}
              >
                <SportsEsportsIcon
                  sx={
                    selectedController === 'gamepad'
                      ? { color: '#000' }
                      : { color: '#fff' }
                  }
                />
                {t('vehicle.gp')}
              </Typography>
              <Typography
                variant='caption'
                sx={{ display: 'flex', alignItems: 'center' }}
                className={
                  selectedController === 'radiomaster'
                    ? classes.sweepersNonTypeItem
                    : classes.sweepersTypeItem
                }
                onClick={() => {
                  setSelectedController('radiomaster');
                  dispatch(setController('radiomaster'));
                  setControllerTypeVisible(false);
                }}
              >
                <CellTowerIcon
                  sx={
                    selectedController !== 'gamepad'
                      ? { color: '#000' }
                      : { color: '#fff' }
                  }
                />
                {t('vehicle.rm')}
              </Typography>
            </Box>
          ) : null}
          <IconButton size='large'>
            {selectedController === 'gamepad' ? (
              <SportsEsportsIcon sx={{ color: '#fff' }} />
            ) : (
              <CellTowerIcon sx={{ color: '#fff' }} />
            )}
          </IconButton>
          <Box className={classes.startEngineIconWrapper}>
            <svg
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
              className={classes.controllerIcon}
              style={{ paddingRight: '0.5rem' }}
            >
              <path
                d='M6.71002 14.71C7.10002 15.1 7.73002 15.1 8.12002 14.71L12 10.83L15.88 14.71C16.27 15.1 16.9 15.1 17.29 14.71C17.68 14.32 17.68 13.69 17.29 13.3L12.7 8.71C12.31 8.32 11.68 8.32 11.29 8.71L6.70002 13.3C6.32002 13.68 6.32002 14.32 6.71002 14.71Z'
                fill='white'
                fillOpacity='0.56'
              />
            </svg>
          </Box>
          <Typography variant='caption' className={classes.commandText}>
            {selectedController === 'gamepad'
              ? t('vehicle.gp')
              : t('vehicle.rm')}
          </Typography>
        </Box>
      ) : null}
      {vehicleType === VEHICLE_TYPES.CAR ||
      vehicleType === VEHICLE_TYPES.BOAT ||
      vehicleType === VEHICLE_TYPES.TRACTOR_GEMINI ? (
        <Box
          className={classes.controlItemContainer}
          onClick={() => setControllerTypeVisible(!controllerTypeVisible)}
        >
          <IconButton
            size='large'
            onClick={() => dispatch(setShowSelectController(true))}
            disabled={manualControlMetric}
          >
            <SettingsIcon sx={{ color: '#fff' }} />
          </IconButton>
          {/* {showSelectController ? <SelectController /> : null} */}
          <Typography variant='caption' className={classes.commandText}>
            {t('vehicle.settingsLabel')}
          </Typography>
        </Box>
      ) : null}
      {vehicleType === VEHICLE_TYPES.BOAT ? (
        <>
          <Box
            className={classes.controlItemContainer}
            onClick={() => pumpMainCommands()}
          >
            <IconButton size='large' disabled={manualControlMetric}>
              {pumpMainRunning ? (
                <PauseCircleIcon sx={{ color: '#fff' }} />
              ) : (
                <PlayCircleFilledIcon sx={{ color: '#fff' }} />
              )}
            </IconButton>
            <Typography variant='caption' className={classes.commandText}>
              {pumpMainRunning
                ? t('vehicle.pumpMainOff')
                : t('vehicle.pumpMainOn')}
            </Typography>
          </Box>
          <Box
            className={classes.controlItemContainer}
            onClick={() => pumpEngineCommands()}
          >
            <IconButton size='large' disabled={manualControlMetric}>
              {pumpEngineRunning ? (
                <PauseCircleIcon sx={{ color: '#fff' }} />
              ) : (
                <PlayCircleFilledIcon sx={{ color: '#fff' }} />
              )}
            </IconButton>
            <Typography variant='caption' className={classes.commandText}>
              {pumpEngineRunning
                ? t('vehicle.pumpEngineOff')
                : t('vehicle.pumpEngineOn')}
            </Typography>
          </Box>
        </>
      ) : null}
      {displayVehicleSpecialFunctionality(vehicleType)}
    </>
  );
});

export default CommonControls;
