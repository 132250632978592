import { socketSend } from '../../../infra/common/socketService';
import { useAppDispatch, useAppSelector } from '../../../store';
import radioCommand from '../../../infra/common/socketService/commands/radiomasterCommands';
import { setIsStopRadiomasterControllAvailable } from '../../../store/vehicle/vehicle.slice';

interface SerialPort {
  open(options: { baudRate: number }): Promise<void>;
  readable: ReadableStream<Uint8Array> | null;
  close(): Promise<void>;
}

let reader: ReadableStreamDefaultReader<Uint8Array>;
let port: SerialPort;
const arrayOfCommandsForSend = Array.from(Array(26).keys());
let radioCommandInterval: NodeJS.Timeout;

const useRadiomaster = () => {
  const timeProcessing = useAppSelector((state) => state.app.timeProcessing);
  const dispatch = useAppDispatch();

  const sendCommandSocket = () => {
    radioCommandInterval = setInterval(async () => {
      await socketSend(radioCommand(arrayOfCommandsForSend), timeProcessing);
    }, 100);
  };

  async function connectAndReadSerial(): Promise<void> {
    try {
      // Запрашиваем у пользователя выбор серийного порта
      port = await (navigator as any).serial.requestPort();
      // Устанавливаем параметры подключения (например, скорость передачи данных)
      await port.open({ baudRate: 115000 });
      console.log('OPEN CHOSOE CONTGROLLER');

      // Получаем доступ к читаемому потоку
      reader = port.readable!.getReader();
      // const decoder = new TextDecoder(); // Используем кодировку по умолчанию для декодирования
      let buffer = new Uint8Array();
      sendCommandSocket();
      // Постоянное чтение данных из серийного порта
      while (true) {
        // eslint-disable-next-line
        const { value, done } = await reader.read();
        if (done) {
          // Выход из цикла, если поток закрыт
          break;
        }
        // Обработка полученных данных
        if (value) {
          // Объединяем новые данные с предыдущим буфером
          const newData = new Uint8Array(buffer.length + value.length);
          newData.set(buffer);
          newData.set(value, buffer.length);
          buffer = newData;

          // Обработка пакетов данных
          while (buffer.length >= 26) {
            const packet = buffer.slice(0, 26);
            buffer = buffer.slice(26);

            Object.keys(packet).forEach((key) => {
              arrayOfCommandsForSend[Number(key)] = packet[Number(key)];
            });
          }
        }
      }
    } catch (error) {
      console.error('Ошибка при подключении к серийному порту:', error);
      dispatch(setIsStopRadiomasterControllAvailable(false));
    }
  }

  const stopReadingRadiomaster = async () => {
    if (reader) {
      try {
        await reader.cancel();
        reader.releaseLock();
      } catch (error) {
        console.error('Ошибка при освобождении reader:', error);
      }
    }

    if (port && port.readable) {
      try {
        await port.close();
      } catch (error) {
        console.error('Ошибка при закрытии порта:', error);
      }
    }

    clearInterval(radioCommandInterval);
  };

  return { stopReadingRadiomaster, connectAndReadSerial };
};

export default useRadiomaster;

// Пример использования
// const { connect, disconnect, connectAndReadSerial } = useRadiomaster();
