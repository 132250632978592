import {
  FC,
  forwardRef,
  ReactElement,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import L, { PointExpression } from 'leaflet';
import { Marker } from 'react-leaflet';
import 'leaflet-rotatedmarker';
import { VEHICLE_TYPES } from '../../../../domain/vehicle';
import {
  boatMarker,
  boatMarkerActive,
  carMarker,
  carMarkerActive,
  droneMarker,
  droneMarkerActive,
  tractorMarker,
  tractorMarkerActive,
} from '../Markers/markersSvg';
import { useAppSelector } from '../../../../store';
import { fromRadiansToDegrees, listMapConfig } from '../common/config';

const {
  marker: { rotationOrigin },
} = listMapConfig;

interface IProps {
  children: ReactNode;
  position: [number, number];
  yawValues: number;
  locationPinClickHandler: (position: [number, number]) => void | null;
  vehicleType: string;
  vehicleUuid: string;
}

const RotatedMarker: FC<IProps> = forwardRef(
  (
    {
      children,
      position,
      locationPinClickHandler,
      vehicleType,
      vehicleUuid,
      yawValues,
    },
    // eslint-disable-next-line no-shadow
    forwardRef: any
  ): ReactElement => {
    const markerRef: any = useRef();
    // const yaw = useAppSelector(
    //   (state) => state.socket.vehicleMetrics?.attitude.yaw
    // );
    const rotationAngle: number = useMemo(
      // @ts-ignore
      (): number => fromRadiansToDegrees(yawValues),
      [yawValues]
    );

    const selectedUuidDevice = useAppSelector(
      (state) => state.vehicles.selectedUuidDevice?.uuid
    );

    const iconMarker = () => {
      if (vehicleType === VEHICLE_TYPES.CAR) {
        return selectedUuidDevice && selectedUuidDevice === vehicleUuid
          ? carMarkerActive
          : carMarker;
      }
      if (
        vehicleType === VEHICLE_TYPES.TRACTOR ||
        vehicleType === VEHICLE_TYPES.TRACTOR_GEMINI ||
        vehicleType === VEHICLE_TYPES.TRACTOR_KHTI
      ) {
        return selectedUuidDevice && selectedUuidDevice === vehicleUuid
          ? tractorMarkerActive
          : tractorMarker;
      }
      if (
        vehicleType === VEHICLE_TYPES.DRONE ||
        vehicleType === VEHICLE_TYPES.PLANE
      ) {
        return selectedUuidDevice && selectedUuidDevice === vehicleUuid
          ? droneMarkerActive
          : droneMarker;
      }
      if (vehicleType === VEHICLE_TYPES.BOAT) {
        return selectedUuidDevice && selectedUuidDevice === vehicleUuid
          ? boatMarkerActive
          : boatMarker;
      }
      return 'boat';
    };

    const iconMarkerSize = () => {
      if (selectedUuidDevice === vehicleUuid) {
        return [77, 77] as PointExpression;
      }
      return [62, 62] as PointExpression;
    };

    useEffect(() => {
      const marker = markerRef.current;

      if (marker) {
        marker.setRotationAngle(rotationAngle);
        marker.setRotationOrigin(rotationOrigin);
      }
    }, [rotationAngle, rotationOrigin]);
    return (
      <Marker
        ref={(ref) => {
          markerRef.current = ref;
          if (forwardRef) {
            // eslint-disable-next-line no-param-reassign
            forwardRef.current = ref;
          }
        }}
        icon={L.icon({
          iconUrl: `data:image/svg+xml;base64,${btoa(iconMarker())}`,
          iconSize: iconMarkerSize(),
        })}
        position={position}
        eventHandlers={{
          click: (): void | null => locationPinClickHandler(position),
        }}
      >
        {children}
      </Marker>
    );
  }
);

export default RotatedMarker;
