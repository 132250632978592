import { FC, ReactElement, memo } from 'react';
import Tooltip from '@mui/material/Tooltip';
import Pause from '@mui/icons-material/Pause';
import PlayArrow from '@mui/icons-material/PlayArrow';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import IconButton from '@mui/material/IconButton';
import { UnknownAction } from '@reduxjs/toolkit';
import { useTranslation } from 'react-i18next';
import { PluginHandle } from '../../../../infra/common/janusService';
import { useAppDispatch, useAppSelector } from '../../../../store';
import {
  startStreamingRecord,
  stopStreamingRecord,
} from '../../../../store/vehicle/vehicle.thunks';
import StreamSettings from './StreamSettings';
import StopIcon from '../../../assets/StopIcon';
import Record from '../../../assets/Record';
import NoRecord from '../../../assets/NoRecord';
import { updateCameraState } from '../../../../store/vehicle/vehicle.slice';

interface IProps {
  videoRestarted: boolean;
  videoStarted: boolean;
  videoStopped: boolean;
  videoRecording: boolean;
  camera: number;
  uuid: string;
  videoInitializing: boolean;
  streaming: PluginHandle | null;
  startVideoStreaming: (
    camera: number,
    playStreamDisabled: boolean,
    stopStreamDisabled: boolean
  ) => void;
  stopVideoStreaming: (camera: number) => void;
  setVideoInitializing: (status: boolean) => void;
  playStreamDisabled: boolean;
  stopStreamDisabled: boolean;
  setVideoRecording: (status: boolean) => void;
  videoRecordingLoading: boolean;
  setVideoRecordingLoading: (status: boolean) => void;
  takePhoto: () => void;
  setVideoSaved: (status: boolean) => void;
  reduceButton: boolean;
}

const CommandsControls: FC<IProps> = memo(
  ({
    videoRecording,
    videoRestarted,
    videoInitializing,
    videoStarted,
    streaming,
    camera,
    uuid,
    videoStopped,
    startVideoStreaming,
    stopVideoStreaming,
    playStreamDisabled,
    setVideoRecording,
    videoRecordingLoading,
    setVideoRecordingLoading,
    takePhoto,
    setVideoSaved,
    reduceButton,
    stopStreamDisabled,
  }): ReactElement | null => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const camerasState = useAppSelector((state) => state.vehicles.camerasState);

    const startStopVideoRecord = async (): Promise<void> => {
      if (videoRecordingLoading) {
        return;
      }

      await setVideoRecordingLoading(true);

      if (videoRecording) {
        await dispatch(
          stopStreamingRecord({
            uuid,
            camera,
            onSuccess: (): void => {
              setVideoRecording(false);
              setVideoRecordingLoading(false);
              setVideoSaved(true);
            },
          } as any) as unknown as UnknownAction
        );
      } else {
        await dispatch(
          startStreamingRecord({
            uuid,
            camera,
            onSuccess: (): void => {
              setVideoRecording(true);
              setVideoRecordingLoading(false);
            },
          } as any) as unknown as UnknownAction
        );
      }
    };

    if (videoRestarted) {
      return null;
    }

    if (videoInitializing) {
      console.log(videoInitializing, 'videoInitializing!!!!!!!!!!');
      return (
        <Tooltip
          key='stop'
          placement='top'
          title={
            stopStreamDisabled
              ? t('vehicle.3SecondsWaiting')
              : t('vehicle.stopStreamLabel')
          }
          arrow
        >
          <span
            style={{
              cursor: stopStreamDisabled ? 'not-allowed' : 'pointer',
            }}
          >
            <IconButton
              disabled={stopStreamDisabled}
              color='default'
              size='large'
              onClick={() => stopVideoStreaming(camera)}
            >
              <StopIcon disabled={stopStreamDisabled} />
            </IconButton>
          </span>
        </Tooltip>
      );
    }

    if (videoStarted) {
      return (
        <>
          <Tooltip
            key='photo'
            placement='top'
            title={t('vehicle.takePhotoLabel')}
            arrow
          >
            <IconButton
              color='default'
              size={reduceButton ? 'small' : 'large'}
              onClick={takePhoto}
            >
              <PhotoCameraIcon sx={{ color: '#fff' }} />
            </IconButton>
          </Tooltip>

          <Tooltip
            key='record'
            placement='top'
            title={
              videoRecording
                ? t('vehicle.stopRecordLabel')
                : t('vehicle.startRecordLabel')
            }
            arrow
          >
            <IconButton
              color='default'
              size={reduceButton ? 'small' : 'large'}
              onClick={startStopVideoRecord}
            >
              {videoRecording ? <Record /> : <NoRecord />}
            </IconButton>
          </Tooltip>
          <StreamSettings camera={camera} />
          <Tooltip
            key='stop'
            placement='top'
            title={t('vehicle.stopStreamLabel')}
            arrow
          >
            <IconButton
              data-testid='stopBtn'
              color='default'
              size={reduceButton ? 'small' : 'large'}
              onClick={() => {
                if (camerasState[camera]?.cameraState === true) {
                  dispatch(updateCameraState(camera));
                }

                stopVideoStreaming(camera);
              }}
            >
              <Pause sx={{ color: '#fff' }} />
            </IconButton>
          </Tooltip>
        </>
      );
    }

    if (videoStopped) {
      return (
        <Tooltip
          key='start'
          placement='top'
          title={
            playStreamDisabled
              ? t('vehicle.5SecondsWaiting')
              : t('vehicle.startStreamLabel')
          }
          arrow
        >
          <span
            style={{
              cursor: playStreamDisabled ? 'not-allowed' : 'pointer',
            }}
          >
            <IconButton
              disabled={playStreamDisabled}
              color='default'
              size='large'
              onClick={async () => {
                await startVideoStreaming(
                  camera,
                  playStreamDisabled,
                  stopStreamDisabled
                );
              }}
            >
              <PlayArrow
                sx={{
                  color: '#fff',
                  cursor: playStreamDisabled ? 'not-allowed' : 'pointer',
                }}
              />
            </IconButton>
          </span>
        </Tooltip>
      );
    }

    return (
      <Tooltip
        key='start'
        placement='top'
        title={
          playStreamDisabled
            ? t('vehicle.5SecondsWaiting')
            : t('vehicle.startStreamLabel')
        }
        arrow
      >
        <span
          style={{
            cursor: playStreamDisabled ? 'not-allowed' : 'pointer',
          }}
        >
          <IconButton
            disabled={playStreamDisabled}
            color='default'
            size='large'
            onClick={async () => {
              await startVideoStreaming(
                camera,
                playStreamDisabled,
                stopStreamDisabled
              );
            }}
          >
            <PlayArrow
              fontSize='small'
              sx={{
                color: '#fff',
                cursor: playStreamDisabled ? 'not-allowed' : 'pointer',
              }}
            />
          </IconButton>
        </span>
      </Tooltip>
    );
  }
);

export default CommandsControls;
