import { FC, ReactElement, memo } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import classes from '../../../styles/stream.module.css';

interface IProps {
  videoStarted: boolean;
  videoRestarted: boolean;
  videoPaused: boolean;
  videoInitializing: boolean;
  imageAvailable: boolean;
  loadingMessage: string;
  camera: number;
  slidesLoading: boolean;
}

const LoadingStatus: FC<IProps> = memo(
  ({
    videoStarted,
    loadingMessage,
    camera,
    videoRestarted,
    videoPaused,
    videoInitializing,
    imageAvailable,
    slidesLoading,
  }): ReactElement | null => {
    const { t } = useTranslation();

    if (slidesLoading) {
      return (
        <>
          <CircularProgress />
          <Typography variant='body1' className={classes.videoMessage}>
            {loadingMessage}
          </Typography>
        </>
      );
    }

    if (videoStarted || imageAvailable) {
      return null;
    }

    if (videoRestarted) {
      return (
        <Typography variant='body1' className={classes.videoMessage}>
          {loadingMessage}
        </Typography>
      );
    }

    if (loadingMessage) {
      return (
        <>
          <CircularProgress />
          <Typography variant='body1' className={classes.videoMessage}>
            {loadingMessage}
          </Typography>
        </>
      );
    }

    if (videoPaused) {
      return (
        <Typography variant='body1' className={classes.videoMessage}>
          {t('vehicle.cameraLabel')} {camera + 1} {t('vehicle.onPauseText')}
        </Typography>
      );
    }

    console.log(videoInitializing, 'videoInitializing!@@@@@@@@@@@@@@@@');
    console.log(videoStarted, 'videoStarted!@@@@@@@@@@@@@@@@');

    if (!videoInitializing || !videoStarted) {
      return (
        <Typography variant='body1' className={classes.videoMessage}>
          {t('vehicle.cameraLabel')} {camera + 1}
        </Typography>
      );
    }

    return null;
  }
);

export default LoadingStatus;
