export interface CommonCommand {
  type_task: string;
  task_uuid: null;
  step: null;
  is_check: boolean;
  command: {
    command_to_drone:
      | 'start_manual'
      | 'stop_manual'
      | 'tractor_arm'
      | 'drone_arm'
      | 'plane_arm'
      | 'car_arm'
      | 'arm'
      | 'tractor_gemini_arm'
      | 'tractor_khti_arm'
      | 'tractor_khti_arm_stop'
      | 'car_arm_stop'
      | 'tractor_gemini_arm_stop'
      | 'tractor_gemini_auto_stop'
      | 'tractor_gemini_remove_auto_stop'
      | 'tractor_gemini_lights_on'
      | 'tractor_gemini_lights_off'
      | 'tractor_khti_disarm'
      | 'drone_disarm'
      | 'plane_disarm'
      | 'boat_arm'
      | 'tractor_gemini_disarm'
      | 'car_disarm'
      | 'boat_disarm'
      | 'tractor_disarm'
      | 'boat_arm_stop'
      | 'boat_pump_main_on'
      | 'boat_pump_main_off'
      | 'boat_pump_engine_on'
      | 'boat_pump_engine_off';
    is_online: boolean;
    move_to: null;
    take_off: null;
    ned_velocity: null;
    mission: null;
  };
  telemetry: null;
  is_online: boolean;
  is_force: boolean;
  is_controller: boolean;
  time_processing: { frontend_sent: number };
}

export const setControlCommand = (
  command_to_drone:
    | 'car_controls'
    | 'tractor_controls'
    | 'tractor_reset_controls'
    | 'boat_controls'
    | 'car_reset_controls'
    | 'drone_reset_controls'
    | 'boat_reset_controls'
    | 'tractor_khti_reset_controls'
    | 'tractor_khti_rotate'
    | 'tractor_khti_move'
    | 'tractor_gemini_controls'
    | 'tractor_gemini_reset_controls'
    | 'tractor_gemini_brake_no_clutch',
  controlData: any
): any => ({
  type_task: 'command',
  task_uuid: null,
  step: null,
  is_check: false,
  command: {
    command_to_drone,
    is_online: true,
    move_to: null,
    take_off: null,
    ned_velocity: null,
    [command_to_drone]: controlData,
    mission: null,
  },
  telemetry: null,
  is_online: true,
  is_force: false,
  is_controller: true,
  time_processing: { frontend_sent: 0 },
});

export const startEngineCommand = (
  command_to_drone:
    | 'tractor_arm'
    | 'car_arm'
    | 'tractor_khti_arm'
    | 'tractor_gemini_arm'
    | 'boat_arm'
    | 'plane_arm'
    | 'drone_arm'
    | 'arm'
): CommonCommand => ({
  type_task: 'command',
  task_uuid: null,
  step: null,
  is_check: false,
  command: {
    command_to_drone,
    is_online: true,
    move_to: null,
    take_off: null,
    ned_velocity: null,
    mission: null,
  },
  telemetry: null,
  is_online: true,
  is_force: true,
  is_controller: true,
  time_processing: {
    frontend_sent: 0,
  },
});
export const stopEngineCommand = (
  command_to_drone:
    | 'tractor_khti_arm_stop'
    | 'tractor_gemini_arm_stop'
    | 'car_arm_stop'
    | 'boat_arm_stop'
): CommonCommand => ({
  type_task: 'command',
  task_uuid: null,
  step: null,
  is_check: false,
  command: {
    command_to_drone,
    is_online: true,
    move_to: null,
    take_off: null,
    ned_velocity: null,
    mission: null,
  },
  telemetry: null,
  is_online: true,
  is_force: true,
  is_controller: true,
  time_processing: {
    frontend_sent: 0,
  },
});

export const manualControlCommand = (
  command_to_drone: 'start_manual' | 'stop_manual'
): CommonCommand => ({
  type_task: 'command',
  task_uuid: null,
  step: null,
  is_check: false,
  command: {
    command_to_drone,
    is_online: true,
    move_to: null,
    take_off: null,
    ned_velocity: null,
    mission: null,
  },
  telemetry: null,
  is_online: true,
  is_force: true,
  is_controller: true,
  time_processing: {
    frontend_sent: 0,
  },
});

export const disarmCommand = (
  command_to_drone:
    | 'tractor_khti_disarm'
    | 'tractor_gemini_disarm'
    | 'drone_disarm'
    | 'plane_disarm'
    | 'car_disarm'
    | 'boat_disarm'
    | 'tractor_disarm'
): CommonCommand => ({
  type_task: 'command',
  task_uuid: null,
  step: null,
  is_check: false,
  command: {
    command_to_drone,
    is_online: true,
    move_to: null,
    take_off: null,
    ned_velocity: null,
    mission: null,
  },
  telemetry: null,
  is_online: true,
  is_force: true,
  is_controller: true,
  time_processing: {
    frontend_sent: 0,
  },
});

export const geminiTractorCommands = (
  command_to_drone:
    | 'tractor_gemini_auto_stop'
    | 'tractor_gemini_remove_auto_stop'
    | 'tractor_gemini_lights_on'
    | 'tractor_gemini_lights_off'
): CommonCommand => ({
  type_task: 'command',
  task_uuid: null,
  step: null,
  is_check: false,
  command: {
    command_to_drone,
    is_online: true,
    move_to: null,
    take_off: null,
    ned_velocity: null,
    mission: null,
  },
  telemetry: null,
  is_online: true,
  is_force: true,
  is_controller: true,
  time_processing: {
    frontend_sent: 0,
  },
});
export const pumpCommands = (
  command_to_drone:
    | 'boat_pump_main_on'
    | 'boat_pump_main_off'
    | 'boat_pump_engine_on'
    | 'boat_pump_engine_off'
): CommonCommand => ({
  type_task: 'command',
  task_uuid: null,
  step: null,
  is_check: false,
  command: {
    command_to_drone,
    is_online: true,
    move_to: null,
    take_off: null,
    ned_velocity: null,
    mission: null,
  },
  telemetry: null,
  is_online: true,
  is_force: true,
  is_controller: true,
  time_processing: {
    frontend_sent: 0,
  },
});
