import { FC, ReactElement, useEffect, useMemo, useState } from 'react';
import { UnknownAction } from '@reduxjs/toolkit';
import Alert from '@mui/material/Alert/Alert';
import Snackbar from '@mui/material/Snackbar/Snackbar';
import Button from '@mui/material/Button/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {
  useBlocker,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import Box from '@mui/material/Box';
import { t } from 'i18next';
import VideoStreamContainer from '../components/Vehicle/VideoStream';
import Notifications from '../components/Vehicle/Notifications';
import { useAppDispatch, useAppSelector } from '../../store';
import {
  connectToVehicle,
  getVehicleStatus,
  disconnectFromVehicle,
} from '../../store/vehicle/vehicle.thunks';
import { disconnectFromSocket } from '../../store/socket/socket.thunks';
import { PING_INTERVAL } from '../../infra/common/httpService/constants';
import { getPing } from '../../store/app/app.thunks';
import { setCollapseAdditionalInfoSection } from '../../store/app/app.slice';
import {
  cleanUpVehiclePage,
  clearMapPosition,
  setEngineRunning,
  setGamepadData,
  setGamepadRunning,
  setManualControlRunning,
  clearMediaList,
  setNavigateToViewer,
} from '../../store/vehicle/vehicle.slice';
import {
  setStylesForAdditionalInfoContainer,
  setHeightForVehicleContainer,
  setWidthForStreamingContainer,
} from '../common/helpers/vehicleStyles';
import Gamepad from '../components/Vehicle/Controls/Gamepad';
import Controls from '../components/Vehicle/Controls';
import ControlsVisibility from '../components/Vehicle/Controls/ControlsVisibility';
import useVehicleControls from '../common/hooks/useVehicleControls';
import useVehicleGeminiControls from '../common/hooks/useVehicleGeminiControls';
import useVehicleHTIControls from '../common/hooks/useVehicleHTIControls';
import Metrics from '../components/Vehicle/Metrics';
import { VEHICLE_TYPES } from '../../domain/vehicle';
import GamepadArrowButtons from '../components/Vehicle/Controls/Gamepad/GamepadArrowButtons';
import CarControlsVisualization from '../components/Vehicle/Controls/CarControlsVisualization';
import MiniMap from '../common/map/MiniMap';
import PinnedMiniMap from '../common/map/PinnedMiniMap';
import SelectController from '../components/Vehicle/Controls/SelectController';
import useDroneControls from '../common/hooks/useDroneControls';
import { socketConnect } from '../../infra/common/socketService';
import classes from '../styles/vehicle.module.css';
import useBoatControls from '../common/hooks/useBoatControls';

let pingInterval: NodeJS.Timeout;

const Vehicle: FC = (): ReactElement => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const connectToVehicleSuccess = useAppSelector(
    (state) => state.vehicles.connectToVehicleSuccess
  );
  const vehicleType = useAppSelector(
    (state) => state.vehicles.activeVehicle?.vehicle_type
  );
  const activeVehicle = useAppSelector((state) => state.vehicles.activeVehicle);

  const navigateToViewer = useAppSelector(
    (state) => state.vehicles.navigateToViewer
  );

  const isUserOnline = useAppSelector((state) => state.app.isUserOnline);

  const getPingSuccess = useAppSelector((state) => state.app.getPingSuccess);

  const gamepadRunning = useAppSelector(
    (state) => state.vehicles.gamepadRunning
  );

  const collapseAdditionalInfoSection = useAppSelector(
    (state) => state.app.collapseAdditionalInfoSection
  );
  const pinnedMap = useAppSelector((state) => state.vehicles.pinnedMap);

  const location = useLocation();

  const viewType: string | null = useMemo(() => {
    const queryParams = new URLSearchParams(location.search);
    return queryParams.get('role');
  }, [location.search]);

  const [open, setOpen] = useState<boolean>(true);
  const [openNavigateAlert, setOpenNavigateAlert] = useState<boolean>(false);

  const [isGamepadDisconnected, setIsGamepadDisconnected] =
    useState<boolean>(false);

  const { vehicleUuid } = useParams();

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      currentLocation.pathname !== nextLocation.pathname
  );

  const closeNavigateSnackbar = () => {
    setOpenNavigateAlert(false);
  };

  useEffect(() => {
    pingInterval = setInterval(() => {
      dispatch(getPing() as unknown as UnknownAction);
    }, PING_INTERVAL);

    window.addEventListener('gamepadconnected', (): void => {
      dispatch(setGamepadRunning(true));
      setOpen(true);
      setIsGamepadDisconnected(false);
    });

    window.addEventListener('gamepaddisconnected', (): void => {
      setOpen(true);
      dispatch(setGamepadRunning(false));
      setIsGamepadDisconnected(true);
    });

    return () => {
      dispatch(
        // TODO: click logout
        disconnectFromVehicle(vehicleUuid as string) as unknown as UnknownAction
      );
      dispatch(disconnectFromSocket() as unknown as UnknownAction);
      dispatch(cleanUpVehiclePage());
      clearInterval(pingInterval);
      dispatch(setCollapseAdditionalInfoSection(false));
      dispatch(clearMapPosition());
      dispatch(setEngineRunning(false));
      dispatch(setManualControlRunning(false));
      dispatch(setGamepadData(null));
      dispatch(clearMediaList());
    };
  }, []);

  useEffect(() => {
    return () => {
      localStorage.removeItem('controller');
      localStorage.removeItem('gamepad-index');
    };
  }, []);

  useEffect(() => {
    if (navigateToViewer) {
      setOpenNavigateAlert(true);
      navigate(`/vehicles/${activeVehicle?.uuid}?role=viewer`);
      socketConnect(activeVehicle?.uuid, 'viewer', dispatch);
      dispatch(setNavigateToViewer(false));
    }
  }, [navigateToViewer]);

  useEffect(() => {
    if (isUserOnline && getPingSuccess) {
      dispatch(
        connectToVehicle(vehicleUuid as string) as unknown as UnknownAction
      );
    }
  }, [isUserOnline, getPingSuccess]);

  useEffect(() => {
    if (connectToVehicleSuccess) {
      dispatch(
        getVehicleStatus({ vehicleUuid, viewType } as {
          vehicleUuid: string;
          viewType: string;
        }) as unknown as UnknownAction
      );
    }
  }, [connectToVehicleSuccess]);

  useVehicleControls();
  useVehicleHTIControls();
  useVehicleGeminiControls();
  useDroneControls();
  useBoatControls();

  const showSelectController = () => {
    if (
      vehicleType === VEHICLE_TYPES.BOAT ||
      vehicleType === VEHICLE_TYPES.TRACTOR_GEMINI ||
      vehicleType === VEHICLE_TYPES.CAR
    ) {
      if (activeVehicle?.is_online && viewType === 'controller') {
        return <SelectController />;
      }
    }

    return null;
  };

  return (
    <>
      {blocker.state === 'blocked' ? (
        <Dialog
          open
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title'>
            {t('vehicle.leavePageTitle')}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              {t('vehicle.leavePageMessage')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => blocker.reset()} color='primary'>
              {t('vehicle.stayOnPage')}
            </Button>
            <Button onClick={() => blocker.proceed()} color='primary' autoFocus>
              {t('vehicle.leavePage')}
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
      {vehicleType === VEHICLE_TYPES.TRACTOR_KHTI ? (
        <GamepadArrowButtons />
      ) : null}
      {showSelectController()}
      <Snackbar
        className={classes.snackbarContainer}
        open={openNavigateAlert}
        autoHideDuration={2000}
        onClose={closeNavigateSnackbar}
      >
        <Alert severity='warning'>{t('vehicle.redirectAlert')}</Alert>
      </Snackbar>

      <Box
        id='parent'
        className={classes.vehicleContainer}
        style={setHeightForVehicleContainer(collapseAdditionalInfoSection)}
      >
        <Box
          className={classes.vehicleContainerLeftSide}
          style={setWidthForStreamingContainer(
            collapseAdditionalInfoSection,
            pinnedMap
          )}
        >
          <VideoStreamContainer />
          {pinnedMap ? <PinnedMiniMap /> : null}
          {!pinnedMap && !collapseAdditionalInfoSection ? (
            <Controls vehicleType={vehicleType} />
          ) : null}
        </Box>
        <Box
          className={classes.additionalInfoContainer}
          style={setStylesForAdditionalInfoContainer(
            collapseAdditionalInfoSection
          )}
        >
          <Box className={classes.additionalInfoContainerInner}>
            <MiniMap />
            {!collapseAdditionalInfoSection ? (
              <>
                <Gamepad />
                <CarControlsVisualization />
                <Metrics />
              </>
            ) : null}
          </Box>
        </Box>
        {!collapseAdditionalInfoSection ? (
          <Notifications
            gamepadRunning={gamepadRunning}
            isGamepadDisconnected={isGamepadDisconnected}
            open={open}
            setOpen={setOpen}
          />
        ) : null}
        <ControlsVisibility
          collapseAdditionalInfoSection={collapseAdditionalInfoSection}
        />
      </Box>
    </>
  );
};
export default Vehicle;
