import {
  FC,
  memo,
  ReactElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import adapter from 'webrtc-adapter';
import { UnknownAction } from '@reduxjs/toolkit';
import Janus, {
  JSEP,
  Message,
  PluginHandle,
} from '../../../../infra/common/janusService';
import {
  ATTACHED_VIDEO_PLUGIN_STATUS,
  iceServers,
  janusConfig,
  max_poll_events,
  PARTICIPANTS_DATA_STATUS,
  RESTART_TIMEOUT,
  VIDEO_ROOM_PLUGIN,
  STOP_STREAM_TIMEOUT,
} from '../../../../infra/common/janusService/constants';
import {
  createOpaqueID,
  leaveMessage,
  pauseMessage,
  setCreateAnswerSchema,
  setJoinMessage,
  setListParticipantsMessage,
  startMessage,
} from '../../../../infra/common/janusService/utils';
import { IParticipantsData } from '../../../../infra/common/janusService/types';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { socketSend } from '../../../../infra/common/socketService';
import {
  startVideoStreamingCommand,
  stopVideoStreamingCommand,
  takePhotoCommand,
} from '../../../../infra/common/socketService/commands/streamingCommands';
import { stopSingleFrameModeSchema } from '../../../../infra/common/socketService/commands/singleFrameModeCommands';
import { clearVehicleImage } from '../../../../store/socket/socket.slice';
import CommandsControls from './CommandsControls';
import LoadingStatus from './LoadingStatus';
import LayoutControls from './LayoutControls';
import FullScreenCameras from './FullScreenCameras';
import { stopStreamingRecord } from '../../../../store/vehicle/vehicle.thunks';
import VideoRecordingInfo from './VideoRecordingInfo';
import {
  setIconControlsStyles,
  setSingleVideoStreamOutline,
  setSingleVideoStreamVisibility,
  setStylesForSingleVideoStreamContainer,
} from '../../../common/helpers/vehicleStyles';
import classes from '../../../styles/stream.module.css';
import {
  deleteParticipantDisplay,
  updateCameraState,
  updateParticipantDisplay,
} from '../../../../store/vehicle/vehicle.slice';

const timers: { [key: string]: NodeJS.Timeout } = {};
const bitrateIntervals: { [key: string]: NodeJS.Timeout } = {};
const reconnectTimeouts: { [key: string]: NodeJS.Timeout } = {};
let isCanReconnectWithPublisher = false;

interface IProps {
  camera: number;
  totalCameras: number;
  pinnedCamera: number | null;
  setPinnedCamera: (camera: number | null) => void;
  videoFullScreenCamera: number | null;
  setVideoFullScreenCamera: (camera: number | null) => void;
  totalThreeElements: boolean;
}

const VideoStream: FC<IProps> = memo(
  ({
    camera,
    setPinnedCamera,
    pinnedCamera,
    videoFullScreenCamera,
    setVideoFullScreenCamera,
    totalCameras,
    totalThreeElements,
  }): ReactElement | null => {
    const dispatch = useAppDispatch();
    const roomID = useAppSelector((state) => state.vehicles.activeVehicle?.id);

    const isDeviceOnline = useAppSelector(
      (state) => state.vehicles.activeVehicle?.is_online
    );
    const camerasData = useAppSelector(
      (state) => state.socket?.vehicleMetrics?.cameras_data
    );
    const activeParticipants = useAppSelector(
      (state) => state.vehicles?.activeParticipants
    );
    const uuid = useAppSelector((state) => state.vehicles.activeVehicle?.uuid);
    const pinnedMap = useAppSelector((state) => state.vehicles.pinnedMap);
    const isUserOnline = useAppSelector((state) => state.app.isUserOnline);
    const getPingSuccess = useAppSelector((state) => state.app.getPingSuccess);
    const timeProcessing = useAppSelector((state) => state.app.timeProcessing);
    const vehicleImage = useAppSelector(
      (state) => state.socket.vehicleImages[camera]
    );
    const socketStatus = useAppSelector((state) => state.socket.socketStatus);
    const camerasState = useAppSelector((state) => state.vehicles.camerasState);

    const [videoInitializing, setVideoInitializing] = useState<boolean>(false);
    const [videoFullScreenMode, setVideoFullScreenMode] =
      useState<boolean>(false);
    const [slidesLoading, setSlidesLoading] = useState<boolean>(false);
    const [videoHovered, setVideoHovered] = useState<boolean>(false);
    const [videoStarted, setVideoStarted] = useState<boolean>(false);
    const [videoStopped, setVideoStopped] = useState<boolean>(false);
    const [videoPaused, setVideoPaused] = useState<boolean>(false);
    const [videoWasStarted, setVideoWasStarted] = useState<boolean>(false);
    const [videoRestarted, setVideoRestarted] = useState<boolean>(false);
    const [loadingMessage, setLoadingMessage] = useState<string>('');
    const [janus, setJanus] = useState<Janus | null>(null);
    const [isCanReconnect, setIsCanReconnect] = useState<boolean>(false);
    const [streaming, setStreaming] = useState<PluginHandle | null>(null);
    const [streamExisted, setStreamExisted] = useState<boolean>(false);
    const [playStreamDisabled, setPlayStreamDisabled] =
      useState<boolean>(false);
    const [stopStreamDisabled, setStopStreamDisabled] =
      useState<boolean>(false);
    const collapseAdditionalInfoSection = useAppSelector(
      (state) => state.app.collapseAdditionalInfoSection
    );
    const [smallTimeout, setSmallTimeout] = useState<boolean>(false);
    const lastExecutionTimeRef = useRef<number>(0);

    const [videoRecordingLoading, setVideoRecordingLoading] =
      useState<boolean>(false);
    const [videoRecording, setVideoRecording] = useState<boolean>(false);
    const [videoSaved, setVideoSaved] = useState<boolean>(false);

    const videoRef = useRef<HTMLVideoElement>(null);
    const { t } = useTranslation();

    const currentTime = Date.now();

    const viewType: string | null = useMemo(() => {
      // eslint-disable-next-line no-restricted-globals
      const queryParams = new URLSearchParams(location.search);
      // eslint-disable-next-line no-restricted-globals
      return queryParams.get('role');
      // eslint-disable-next-line no-restricted-globals
    }, [location.search]);

    useEffect(() => {
      if (vehicleImage) {
        dispatch(clearVehicleImage(camera));
      }

      return () => {
        clearInterval(timers[camera]);
        clearInterval(bitrateIntervals[camera]);
        clearTimeout(reconnectTimeouts[camera]);
        stopVideoStreaming(camera);
        dispatch(clearVehicleImage(camera));
        setVideoWasStarted(false);

        if (vehicleImage) {
          socketSend(stopSingleFrameModeSchema(camera), timeProcessing);
        }
      };
    }, []);

    useEffect(() => {
      if (stopStreamDisabled && !isCanReconnect) {
        setTimeout(() => {
          setStopStreamDisabled(false);
          clearInterval(bitrateIntervals[camera]);
          clearTimeout(reconnectTimeouts[camera]);
        }, STOP_STREAM_TIMEOUT);
      }
    }, [stopStreamDisabled, isCanReconnect]);

    useEffect(() => {
      if (playStreamDisabled) {
        setTimeout(
          () => {
            setPlayStreamDisabled(false);
            setSmallTimeout(false);
            clearInterval(bitrateIntervals[camera]);
            clearTimeout(reconnectTimeouts[camera]);
          },
          smallTimeout ? 500 : RESTART_TIMEOUT
        );
      }
    }, [playStreamDisabled, isCanReconnect, smallTimeout]);

    useEffect(() => {
      if (slidesLoading) {
        if (videoInitializing || vehicleImage) {
          setSlidesLoading(false);
          setLoadingMessage('');
        }
      }
    }, [slidesLoading, videoInitializing, vehicleImage]);

    useEffect(() => {
      if (streamExisted && isUserOnline && isDeviceOnline && getPingSuccess) {
        setStreamExisted(false);
        stopVideoStreaming(camera);
      }
    }, [isUserOnline, streamExisted, isDeviceOnline, getPingSuccess]);

    useEffect(() => {
      if (videoFullScreenCamera !== null && videoFullScreenCamera !== camera) {
        if (videoStarted) {
          pauseVideoStreaming();
        }
      } else if (videoPaused) {
        restartVideoStreaming();
      }
    }, [videoFullScreenCamera, videoFullScreenMode]);

    useEffect(() => {
      if (videoSaved) {
        setTimeout(() => {
          setVideoSaved(false);
        }, 3000);
      }
    }, [videoSaved]);

    const cleanUpStates = (): void => {
      setJanus(null);
      setStreaming(null);
      clearInterval(timers[camera]);
      clearTimeout(reconnectTimeouts[camera]);
      setVideoStopped(true);
      setLoadingMessage('');
      setVideoInitializing(false);
      setVideoStarted(false);
      setVideoPaused(false);
      setVideoWasStarted(false);

      if (vehicleImage) {
        dispatch(clearVehicleImage(camera));
        socketSend(stopSingleFrameModeSchema(camera), timeProcessing);
      }
    };

    const cleanUpStatesPublisher = (): void => {
      setJanus(null);
      setStreaming(null);
      clearInterval(timers[camera]);
      clearTimeout(reconnectTimeouts[camera]);
      setVideoStarted(false);
      setVideoPaused(false);
    };

    useEffect(() => {
      if (socketStatus === 'disconnected') {
        setIsCanReconnect(true);
        setVideoInitializing(false);
        lastExecutionTimeRef.current = currentTime;
      }
    }, [socketStatus]);

    useEffect(() => {
      if (!isUserOnline) {
        cleanUpStates();
      }
    }, [isUserOnline]);

    console.log(
      activeParticipants,
      'activeParticipants[camera]?.id',
      camera,
      'camera'
    );

    useEffect(() => {
      if (
        camerasData[camera].publisher_id &&
        videoWasStarted &&
        isCanReconnectWithPublisher
      ) {
        if (
          activeParticipants[`camera_${camera}`]?.id !==
          Number(camerasData[camera].publisher_id)
        ) {
          console.log('work!!!#@!@#!@#!@#!@#!');
          startVideoStreamingPublisher(camera);
        }
      }

      if (camerasData[camera].publisher_id === null && videoWasStarted) {
        setLoadingMessage(t('vehicle.searchCamerasLabel'));
        setVideoInitializing(true);
        stopVideoStreamingPublisher(camera);
      }
    }, [camerasData[camera].publisher_id, activeParticipants]);

    const startVideoStreaming = async (
      camera: number,
      playStreamDisabled: boolean,
      stopStreamDisabled: boolean
    ) => {
      isCanReconnectWithPublisher = true;
      if (camerasState[camera]?.cameraState === false) {
        dispatch(updateCameraState(camera));
      }

      if (playStreamDisabled) {
        return;
      }

      if (isCanReconnect) {
        setIsCanReconnect(false);
      }

      if (!stopStreamDisabled) {
        await setStopStreamDisabled(true);
      }
      setLoadingMessage(t('vehicle.searchCamerasLabel'));
      // await socketSend(stopSingleFrameModeSchema(camera), timeProcessing);
      setTimeout(async () => {
        await dispatch(clearVehicleImage(camera));
      }, 2000);
      await setVideoInitializing(true);
      await socketSend(startVideoStreamingCommand(camera), timeProcessing);

      if (
        viewType === 'controller' &&
        Number(camerasData[camera].publisher_id)
      ) {
        connectToRoom(camera);
      }

      if (viewType === 'viewer' && Number(camerasData[camera].publisher_id)) {
        connectToRoom(camera);
      }

      if (!videoWasStarted) {
        setVideoWasStarted(true);
      }
    };

    const connectToRoom = async (camera: number) => {
      // if (camerasState[camera]?.cameraState === false) {
      //   dispatch(updateCameraState(camera));
      // }

      // setIsCanReconnect(false);
      // setVideoInitializing(true);

      await Janus.init({
        debug: true,
        dependencies: Janus.setDefaultDependencies({ adapter }),
      });

      let localStream: PluginHandle | null = null;
      const janus = new Janus({
        server: janusConfig.url as string,
        apisecret: janusConfig.apiSecret,
        iceServers,
        success: (): void => {
          janus.attach({
            plugin: VIDEO_ROOM_PLUGIN,
            opaqueId: createOpaqueID(),
            success: (pluginHandle: PluginHandle): void => {
              localStream = pluginHandle;
              setStreaming(localStream);

              // Присоединение к существующей комнате
              (localStream as PluginHandle).send({
                ...setJoinMessage(
                  roomID,
                  Number(camerasData[camera].publisher_id)
                ),
              });

              // Обновляем отображаемое имя участника
              if (Number(camerasData[camera].publisher_id)) {
                dispatch(
                  updateParticipantDisplay({
                    id: Number(camerasData[camera].publisher_id),
                    display: camera.toString(),
                    publisher: true,
                  })
                );
              }
            },
            onmessage: (message: Message, jsep: JSEP | undefined): void => {
              if (message.videoroom === ATTACHED_VIDEO_PLUGIN_STATUS && jsep) {
                (localStream as PluginHandle).createAnswer({
                  jsep,
                  success: (jsep: JSEP) => {
                    (localStream as PluginHandle).send({
                      ...startMessage,
                      jsep,
                    });
                    setVideoStarted(true);
                  },
                });
              }
            },
            onremotetrack: (track: MediaStreamTrack): void => {
              if (!track.muted && videoRef.current) {
                setVideoInitializing(false);
                Janus.attachMediaStream(
                  videoRef.current,
                  new MediaStream([track])
                );
              }
            },
          });
        },
        error: () => {
          setStreamExisted(true);
        },
      });

      setJanus(janus);
    };

    const startVideoStreamingPublisher = async (camera: number) => {
      if (camerasState[camera]?.cameraState === false) {
        dispatch(updateCameraState(camera));
      }

      if (isCanReconnect) {
        setIsCanReconnect(false);
      }

      // await socketSend(stopSingleFrameModeSchema(camera), timeProcessing);
      setTimeout(async () => {
        await dispatch(clearVehicleImage(camera));
      }, 2000);
      await setVideoInitializing(true);
      await Janus.init({
        debug: true,
        dependencies: Janus.setDefaultDependencies({ adapter }),
      });

      let localStream: PluginHandle | null = null;
      const janus = new Janus({
        server: janusConfig.url as string,
        apisecret: janusConfig.apiSecret,
        iceServers,
        max_poll_events,
        success: (): void => {
          janus.attach({
            plugin: VIDEO_ROOM_PLUGIN,
            opaqueId: createOpaqueID(),
            success: (pluginHandle: PluginHandle): void => {
              localStream = pluginHandle;
              setStreaming(localStream);
              setVideoRestarted(false);
              setLoadingMessage(t('vehicle.configurationCompletedLabel'));

              const getParticipants = (): void => {
                (localStream as PluginHandle).send({
                  ...setListParticipantsMessage(roomID),
                  success: (participantsData: IParticipantsData): void => {
                    if (
                      participantsData.videoroom === PARTICIPANTS_DATA_STATUS &&
                      participantsData.participants.length
                    ) {
                      setLoadingMessage(t('vehicle.startStreamingLabel'));

                      (localStream as PluginHandle).send({
                        ...setJoinMessage(
                          roomID,
                          Number(camerasData[camera].publisher_id)
                        ),
                      });
                      clearInterval(timers[camera]);

                      if (Number(camerasData[camera].publisher_id)) {
                        dispatch(
                          updateParticipantDisplay({
                            id: Number(camerasData[camera].publisher_id),
                            display: camera.toString(),
                            publisher: true,
                          })
                        );
                      }
                    } else if (
                      participantsData?.error_code === 426 &&
                      participantsData.error?.startsWith('No such room')
                    ) {
                      setLoadingMessage(
                        `${t('vehicle.noConnectToRoom')} ${roomID}`
                      );
                    } else {
                      setLoadingMessage(t('vehicle.searchCamerasLabel'));
                    }
                  },
                });
              };

              timers[camera] = setInterval((): void => getParticipants(), 1000);
            },
            onmessage: (message: Message, jsep: JSEP | undefined): void => {
              if (message.videoroom === ATTACHED_VIDEO_PLUGIN_STATUS && jsep) {
                (localStream as PluginHandle).createAnswer({
                  ...setCreateAnswerSchema(jsep?.sdp, jsep?.type),
                  success: (jsep: JSEP) => {
                    (localStream as PluginHandle).send({
                      ...startMessage,
                      jsep,
                    });
                    setVideoStarted(true);
                  },
                });
              }
            },
            onremotetrack: (track: MediaStreamTrack): void => {
              const stream: MediaStream = new MediaStream([track]);
              if (track.muted === false) {
                setVideoInitializing(false);
              }
              if (videoRef && videoRef.current) {
                Janus.attachMediaStream(videoRef.current, stream);
              }
            },
          });
        },
        error: async (): Promise<void> => {
          setStreamExisted(true);
        },
        destroyed: (): void => {
          if (streaming) {
            streaming.send({ ...leaveMessage });
            streaming.hangup();
          }
        },
      });
      setJanus(janus);
    };

    const stopVideoStreaming = async (camera: number): Promise<void> => {
      isCanReconnectWithPublisher = false;

      dispatch(deleteParticipantDisplay(camera));
      if (stopStreamDisabled) {
        return;
      }

      if (videoInitializing) {
        setVideoInitializing(false);
      }

      await socketSend(stopVideoStreamingCommand(camera), timeProcessing);

      if (!playStreamDisabled) {
        await setPlayStreamDisabled(true);
      }
      setLoadingMessage('');
      if (janus) {
        janus.destroy({
          notifyDestroyed: true,
          success: async () => {
            cleanUpStates();
            if (videoRecording) {
              await dispatch(
                stopStreamingRecord({
                  uuid,
                  camera,
                  onSuccess: () => {
                    setVideoRecording(false);
                    setVideoRecordingLoading(false);
                  },
                } as any) as unknown as UnknownAction
              );
            }
          },
        });
      }
    };

    const stopVideoStreamingPublisher = async (
      camera: number
    ): Promise<void> => {
      if (stopStreamDisabled) {
        return;
      }
      dispatch(deleteParticipantDisplay(camera));
      if (!playStreamDisabled) {
        await setPlayStreamDisabled(true);
      }

      if (janus) {
        janus.destroy({
          notifyDestroyed: true,
          success: async () => {
            cleanUpStatesPublisher();
          },
        });
      }
    };

    const restartVideoStreaming = async (): Promise<void> => {
      if (streaming) {
        await streaming.send({ ...startMessage });
      }

      setVideoInitializing(false);
      setVideoPaused(false);
      setVideoStopped(false);
      setVideoStarted(true);
    };

    const pauseVideoStreaming = async (): Promise<void> => {
      if (streaming) {
        await streaming.send({
          ...pauseMessage,
        });
      }

      if (videoRecording) {
        await dispatch(
          stopStreamingRecord({
            uuid,
            camera,
            onSuccess: () => {
              setVideoRecording(false);
              setVideoRecordingLoading(false);
            },
          } as any) as unknown as UnknownAction
        );
      }

      setVideoInitializing(false);
      setVideoStarted(false);
      setVideoStopped(false);
      setVideoPaused(true);
    };

    const takePhoto = async (): Promise<void> => {
      await socketSend(takePhotoCommand(camera), timeProcessing);
    };

    return (
      <Box
        className={`${classes.videoWrapper} ${totalThreeElements && pinnedCamera === null ? classes.thirdElement : ''}`}
        style={{
          ...setStylesForSingleVideoStreamContainer(
            videoFullScreenCamera === camera,
            pinnedCamera,
            pinnedCamera === camera,
            collapseAdditionalInfoSection,
            totalCameras,
            pinnedMap
          ),
          ...setSingleVideoStreamOutline(
            videoHovered,
            totalCameras,
            videoFullScreenCamera !== camera
          ),
        }}
        onMouseEnter={() => setVideoHovered(true)}
        onMouseLeave={() => setVideoHovered(false)}
      >
        {vehicleImage ? (
          <img
            src={`data:image/jpeg;base64,${vehicleImage}` as string}
            alt='Crop'
            className={classes.videoStreamMedia}
          />
        ) : (
          <video
            autoPlay
            muted
            playsInline
            ref={videoRef}
            className={classes.videoStreamMedia}
            style={setSingleVideoStreamVisibility(videoStarted)}
          />
        )}
        {videoRecording || videoSaved ? (
          <VideoRecordingInfo
            camera={camera}
            videoSaved={videoSaved}
            fullScreen={videoFullScreenCamera === camera}
          />
        ) : null}
        {videoHovered && totalCameras > 1 ? (
          <LayoutControls
            setVideoFullScreenCamera={setVideoFullScreenCamera}
            videoFullScreenCamera={videoFullScreenCamera}
            setVideoHovered={setVideoHovered}
            pinnedCamera={pinnedCamera}
            setPinnedCamera={setPinnedCamera}
            setVideoFullScreenMode={setVideoFullScreenMode}
            camera={camera}
            pinnedMap={pinnedMap}
          />
        ) : null}
        {videoFullScreenCamera === camera &&
        totalCameras > 1 &&
        videoHovered ? (
          <FullScreenCameras
            camera={camera}
            totalCameras={totalCameras}
            setVideoFullScreenCamera={setVideoFullScreenCamera}
            setVideoFullScreenMode={setVideoFullScreenMode}
          />
        ) : null}
        {!videoStarted ? (
          <Box className={classes.loadingStatusContainer}>
            <LoadingStatus
              loadingMessage={loadingMessage}
              videoInitializing={videoInitializing}
              videoRestarted={videoRestarted}
              videoStarted={videoStarted}
              videoPaused={videoPaused}
              imageAvailable={!!vehicleImage}
              camera={camera}
              slidesLoading={slidesLoading}
            />
          </Box>
        ) : null}
        {videoHovered ? (
          <Box
            className={classes.iconControls}
            style={setIconControlsStyles(pinnedMap || pinnedCamera !== null)}
          >
            <CommandsControls
              camera={camera}
              uuid={uuid}
              streaming={streaming}
              videoRecording={videoRecording}
              videoStopped={videoStopped}
              videoInitializing={videoInitializing}
              videoRestarted={videoRestarted}
              videoStarted={videoStarted}
              startVideoStreaming={startVideoStreaming}
              stopVideoStreaming={stopVideoStreaming}
              setVideoInitializing={setVideoInitializing}
              playStreamDisabled={playStreamDisabled}
              stopStreamDisabled={stopStreamDisabled}
              setVideoRecording={setVideoRecording}
              videoRecordingLoading={videoRecordingLoading}
              setVideoRecordingLoading={setVideoRecordingLoading}
              takePhoto={takePhoto}
              setVideoSaved={setVideoSaved}
              reduceButton={pinnedMap || pinnedCamera !== camera}
            />
          </Box>
        ) : null}
      </Box>
    );
  }
);

export default VideoStream;
